<template>
	<div class="pd_people">
		<div class="pd_people_line"> </div>
		<div class="pd_people_line_red">
			<div class="pd_people_line_red_sub"> </div>
		</div>
		<div class="pd_people_title"> SPEAKERS </div>
		<div class="pd_people_list">
			<div
				class="pd_people_item"
				v-for="(item, index) in data"
				:key="index"
				v-show="isAll ? true : index < 10"
			>
				<div class="pd_people_item_img">
					<img :src="item.header" alt="" />
				</div>
				<div class="pd_people_item_name">{{ item.name }}</div>
				<div
					class="pd_people_item_ceo"
					:style="{ fontSize: item.fontsize + 'px' }"
				>
					{{ item.ceo }}</div
				>
				<div
					class="pd_people_item_c"
					:style="{ fontSize: item.cFontsize + 'px' }"
				>
					{{ item.c }}</div
				>
				<div class="pd_people_item_line"></div>
			</div>
		</div>

		<div
			class="pd_people_more pointer"
			v-show="!isAll"
			@click="
				$router.push({
					path: '/polkadot2024_speaker',
				})
			"
		>
			<img
				:src="currentImage"
				alt=""
				@mouseover="changeImage('b')"
				@mouseleave="changeImage('a')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		isAll: {
			type: Boolean,
			default: false,
		},
	},
	created() {},
	data() {
		return {
			currentIndex: 0,
			currentImage:
				"https://res.metaera.hk/resources/assets/images/event/24.png", // 初始图片
			images: {
				a: "https://res.metaera.hk/resources/assets/images/event/24.png", // 图片A
				b: "https://res.metaera.hk/resources/assets/images/event/241.png", // 图片B
			},
			data: [
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Polkadot",
					name: "Dr. Gavin Wood",
					ceo: "Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p22.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Web3 Foundation",
					name: "Fabian Gompf",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/fabi - Louis Z.png",
				},
				{
					fontsize: 9,
					cFontsize: 16,
					c: "Parity Technologies",
					name: "Chrissy Hill",
					ceo: "Chief Legal Officer and Interim Chief Operating Officer",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p34.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Web3 Foundation",
					name: "Seraya Takahashi",
					ceo: "Ecosystem Development",
					header:
						"https://res.metaera.hk/resources/assets/images/event/2024-08-19 19.20.44 - Seraya Takahashi.png",
				},
				{
					fontsize: 10,
					cFontsize: 14,
					c: "Polkadot",
					name: "Helena Wang",
					ceo: "Host and Stage Moderator of Polkadot Decoded Asia",
					header:
						"https://res.metaera.hk/resources/assets/images/event/photo_2024-08-19_14-56-01 - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 12,
					c: "Stanford Blockchain Accelerator",
					name: "Kun Peng",
					ceo: "Founder at SBA & Founder at BASS",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p28.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Harbour Industrial Capital",
					name: "Max Rebol",
					ceo: "CEO",
					header: "https://res.metaera.hk/resources/assets/images/event/p1.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Centrifuge",
					name: "Martin Quensel",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Martin Headshot - Christie Chapman.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Astar Network",
					name: "Sota Watanabe",
					ceo: "Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p30.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Scytale Digital",
					name: "Mark Cachia",
					ceo: "CIO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p25.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Moonsong Labs",
					name: "Derek Yoo",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Derek-Yoo - Yoki Pan.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Pantera Capital",
					name: "Nick Zurick",
					ceo: "Head of Portfolio Talent",
					header: "https://res.metaera.hk/resources/assets/images/event/p2.png",
				},
				{
					fontsize: 10,
					cFontsize: 12,
					c: "AlibabaCloud International - South APAC",
					name: "James LIU, PMP MBA",
					ceo: "Director of New Business & Innovation Alliance",
					header: "https://res.metaera.hk/resources/assets/images/event/p3.png",
				},
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "MetaEra",
				// 	name: "Jessica",
				// 	ceo: "CEO",
				// 	header:
				// 		"https://res.metaera.hk/resources/assets/images/event/46d4488b-b173-4e26-88d8-cb782a54ee2d.png",
				// },
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Polkadot SEA | OpenGuild",
					name: "Patricia Arro",
					ceo: "Lead Contributor",
					header: "https://res.metaera.hk/resources/assets/images/event/p4.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Acala",
					name: "Yuzhu",
					ceo: "BD & Marketing, APAC",
					header: "https://res.metaera.hk/resources/assets/images/event/p7.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "SubWallet",
					name: "DJ Hà Trang",
					ceo: "Research Lead",
					header: "https://res.metaera.hk/resources/assets/images/event/p8.png",
				},
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Xcharge Digital",
				// 	name: "Jeff Xu",
				// 	ceo: "Founder",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p11.png",
				// },
				{
					fontsize: 14,
					cFontsize: 16,
					c: "HTX Ventures",
					name: "Edward",
					ceo: "Managing Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p12.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "AIWEB3",
					name: "Dr. Cao",
					ceo: "Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/8dda9bf9-adcb-4c4d-a274-2fb8932f1bda.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "OpenSquare",
					name: "Yongfeng Li",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p15.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Moondance Labs",
					name: "Rhee",
					ceo: "Polkadot APAC BD",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p16.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Oneblock+",
					name: "Tracy",
					ceo: "Community Manager",
					header:
						"https://res.metaera.hk/resources/assets/images/event/41dec2e1-c800-40a7-aeba-bd7aeb283759.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "CESS Network",
					name: "Nicholas Zaldastani",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p18.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Unique Network",
					name: "Charu Sethi",
					ceo: "CMO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p19.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Bifrost",
					name: "Tyrone",
					ceo: "Dev Relation",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p20.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "SubQuery Network",
					name: "James Bayly",
					ceo: "COO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p21.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "StellaSwap",
					name: "Aziz Zainuddin",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p23.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Moonbeam",
					name: "Alberto Viera",
					ceo: "DevRel Lead",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p24.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Crypto.com Capital",
					name: "Ashley Ouyang",
					ceo: "Executive Director",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p26_1.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Sovereign Nature Initiative",
					name: "Catherine Bischoff",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p27.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Polimec",
					name: "Luca von Wyttenbach",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p29.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "R0GUE IO",
					name: "Tin Chung",
					ceo: "Software Engineer",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p31.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "OKX Ventures",
					name: "Benson",
					ceo: "Investor",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p32.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "SubQuery Network",
					name: "Marta Adamczyk",
					ceo: "Head of BD",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p33.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "SNZ",
					name: "Larry Ma",
					ceo: "CSO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/p35.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "ltering Tech",
					name: "Denny",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/image (8) - Denny Wang.png",
				},

				{
					fontsize: 14,
					cFontsize: 16,
					c: "PolkaWorld",
					name: "Kristen",
					ceo: "Head of Eco Research",
					header:
						"https://res.metaera.hk/resources/assets/images/event/WechatIMG16 - S hen Kristen.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "HashKey Capital",
					name: "Ryan",
					ceo: "Managing Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/NFT - Ryan CHEN.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "金色财经",
					name: "Luna Hao",
					ceo: "Head of Marketing",
					header:
						"https://res.metaera.hk/resources/assets/images/event/yue.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "PANONY&PANews",
					name: "Yolanda Chung",
					ceo: "Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/2024-05-10 17.18.16 - Presley Sun.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Apillon",
					name: "Nino Kutnjak",
					ceo: "CPO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/IMG20230407095812 - Domen Ursic.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "C² Ventures",
					name: "Ciara Sun",
					ceo: "Founder and Managing Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/20240815-145531 - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Jsquare",
					name: "Joanna Liang",
					ceo: "CEO and Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/mmexport1721730737549 - Betty Wang - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "CognideX",
					name: "James Pang",
					ceo: "Professor at NUS",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Professor Pang - Jung Hyun Park.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Papermoon",
					name: "Nicolas Hussein",
					ceo: "DevRel Engineer",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Hussein,Nicolas-ProfilePicture-Token2049 - Nicolas Hussein.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Masa",
					name: "Brendan Playford",
					ceo: "Co-Founder",
					header:
						"https://res.metaera.hk/resources/assets/images/event/brendan.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Subscan",
					name: "Yakio Liu",
					ceo: "Lead of Subscan",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Yakio_Subscan - Yakio Liu.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Wachsman",
					name: "David Wachsman",
					ceo: "Chief Executive Officer",
					header:
						"https://res.metaera.hk/resources/assets/images/event/David Wachsman 2824x2824 - Jackie Gabbert.png",
				},

				{
					fontsize: 14,
					cFontsize: 16,
					c: "Google Cloud",
					name: "Kyle Song",
					ceo: "APAC Web3 Specialist",
					header:
						"https://res.metaera.hk/resources/assets/images/event/WechatIMG5 - Victoria Li.png",
				},
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Agoric",
				// 	name: "Dean Tribble",
				// 	ceo: "CEO",
				// 	header:
				// 		"https://res.metaera.hk/resources/assets/images/event/Dean_Tribble_Headshot - molly boothman.png",
				// },
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Foundry",
					name: "Emerick Mary",
					ceo: "Product Manager",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Emerick_Mary - Émerick Mary.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Zeeve",
					name: "Ghan Vashishtha",
					ceo: "Co-Founder & CTO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/1663312395929 - Aharna Haque.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Mythical Games",
					name: "John Linden",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/SQUARE_MYTHICAL_WHITE - Kasper Joergensen.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Phala",
					name: "Zoé Meckbach",
					ceo: "Polkadot Head Ambassador",
					header:
						"https://res.metaera.hk/resources/assets/images/event/headshotzoemc. - Zoé.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Blockmedia",
					name: "Andi Ain Jung",
					ceo: "Growth Lead",
					header:
						"https://res.metaera.hk/resources/assets/images/event/정아인 증명사진 - A-in Andi Jung.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "IOSG Ventures",
					name: "Gokhan Er",
					ceo: "Managing Director",
					header:
						"https://res.metaera.hk/resources/assets/images/event/photo_2024-08-22_11-57-20 - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "BlockTempo",
					name: "Arthur",
					ceo: "Editor",
					header:
						"https://res.metaera.hk/resources/assets/images/event/img.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "CoinPost",
					name: "Ryo Sakai",
					ceo: "Head of Business Development",
					header:
						"https://res.metaera.hk/resources/assets/images/event/スピーカー写真 - Ryo Sakai.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Sylvanus Technologies",
					name: "Wilfred Daye",
					ceo: "CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "dlcBTC",
					name: "Aki Balogh",
					ceo: "Co-Founder & CEO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Aki white background - large - Garreth Browne.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "PANONY&PANews",
					name: "Micky Sun",
					ceo: "BD Director",
					header:
						"https://res.metaera.hk/resources/assets/images/event/WechatIMG10544 - Presley Sun.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "HashKey Capital",
					name: "Rui",
					ceo: "Investment manager",
					header:
						"https://res.metaera.hk/resources/assets/images/event/IMG_5444 - zhang yerui.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "IOSG Ventures",
					name: "Figo",
					ceo: "Head of IOSG Kickstarter",
					header:
						"https://res.metaera.hk/resources/assets/images/event/photo_2024-09-06_16-03-49 - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "IVC",
					name: "JT Law",
					ceo: "Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/9909ab1c58d9b195bb4b6b5a5d24bf8f4e9e57f6 - JT Law.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "SNZ Capital",
					name: "Isaac",
					ceo: "Investment Manager",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Isaac Speaker Headshot - Isaac Mung.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Digital Finance Group (DFG)	",
					name: "Terry Culver",
					ceo: "Executive Director",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Terry Culver - Victoria Li.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Jsquare",
					name: "Angela Tong",
					ceo: "CMO",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Jsquare.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Mandala",
					name: "Michael Bennallack",
					ceo: "Co-Founders",
					header:
						"https://res.metaera.hk/resources/assets/images/event/Mandala.png",
				},
				{
					fontsize: 14,
					cFontsize: 16,
					c: "Comma3 Ventures",
					name: "Ivan Li",
					ceo: "Founding Partner",
					header:
						"https://res.metaera.hk/resources/assets/images/event/photo_2024-09-13_14-37-27 - Victoria Li.png",
				},
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "",
				// 	name: "",
				// 	ceo: "",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 12,
				// 	c: "DIN (Data Intelligence Network)",
				// 	name: "Jennie",
				// 	ceo: "Head of Marketing",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p5.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Vara Network",
				// 	name: "Pavel Salas",
				// 	ceo: "Chief Growth Officer",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p6.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Playnation",
				// 	name: "Kate Ha",
				// 	ceo: "CGO",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p9.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Litentry",
				// 	name: "Tim Guo",
				// 	ceo: "Growth Lead",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p10.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Moonbeam",
				// 	name: "Sicco Naets",
				// 	ceo: "Head of Ecosystem Development",
				// 	header: "https://res.metaera.hk/resources/assets/images/event/p13_1.png",
				// },
				// {
				// 	fontsize: 14,
				// 	cFontsize: 16,
				// 	c: "Digital Finance Group (DFG)	",
				// 	name: "James Wo",
				// 	ceo: "Founder & CEO",
				// 	header:
				// 		"https://res.metaera.hk/resources/assets/images/event/James Wo Headshot - Kexin Ye - Victoria Li.png",
				// },
			],
		};
	},
	methods: {
		changeImage(imageKey) {
			console.log("123");

			this.currentImage = this.images[imageKey];
		},
	},
	watch: {},
};
</script>
<!-- pd_people -->
<style scoped>
.pd_people {
	width: 100%;
	padding-top: 48px;
}
.pd_people_line {
	margin-left: 150px;
	margin-right: 150px;
	height: 1px;
	opacity: 1;
	background: #e7e7e7;
}
.pd_people_line_red {
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中，如果需要的话 */
}

.pd_people_line_red_sub {
	margin-top: -1px;
	width: 80px;
	height: 2px;
	opacity: 1;
	background: #e6007a;
}

.pd_people_title {
	margin-top: 30px;
	margin-left: 120px;
	margin-right: 120px;
	font-family: DINPro-Bold;
	font-size: 50px;
	font-weight: bold;
	line-height: normal;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #333333;
}
.pd_people_list {
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
	flex-wrap: wrap;
}
.pd_people_item {
	margin-top: 50px;
	flex: 1 0 20%;
	box-sizing: border-box;
}
.pd_people_item_img {
	width: 100%;
	height: 120px;
	align-items: center;
	text-align: center;
	align-content: center;
	overflow: hidden; /* Hide the overflow */
}
.pd_people_item_img img {
	width: 120px;
	height: 100%;
	border-radius: 14px;
	object-fit: cover; /* Ensure the image covers the container */
	object-position: top; /* Align the image to the top */
}
.pd_people_item_name {
	margin-top: 20px;
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 36px;
	/* display: flex; */
	align-items: center;
	text-align: center;
	align-content: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
}
.pd_people_item_ceo {
	font-family: DINPro-Regular, DINPro;
	font-size: 14px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;

	align-items: center;
	letter-spacing: 0em;

	align-items: center;
	text-align: center;
	align-content: center;
	font-variation-settings: "opsz" auto;
	color: #666666;
}
.pd_people_item_c {
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 36px;
	text-align: center;

	align-items: center;
	text-align: center;
	align-content: center;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}
.pd_people_item_line {
	margin-top: 26px;
	width: 60%;
	margin-left: 20%;
	height: 1px;
	border-radius: 13px;
	opacity: 1;
	background: #dfdfdf;
}

.pd_people_more {
	padding-bottom: 60px;
	margin-top: 34px;
	width: 100%;
	height: 50px;
	/* display: flex; */
	justify-content: center;
	align-items: center;
	text-align: center;
}
.pd_people_more img {
	width: 164px;
	height: 50px;
}
@media screen and (max-width: 900px) {
	.pd_people {
		padding-top: 28px;
	}
	.pd_people_line {
		margin-left: 20px;
		margin-right: 20px;
		height: 1px;
		opacity: 1;
		background: #e7e7e7;
	}
	.pd_people_item {
		margin-top: 20px;
		flex: 1 0 50%;
		box-sizing: border-box;
	}
	.pd_people_title {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 24px;
	}
	.pd_people_item_name {
		margin-top: 20px;
		font-size: 16px;
	}
	.pd_people_item_ceo {
		margin-top: 5px;
		font-size: 14px;
		line-height: 0px;
	}
	.pd_people_item_c {
		margin-top: 10px;
		line-height: 20px;
	}
}
</style>
